import { LocalEnv } from "../types/EnvTypes";

export const LoadEnv : () => LocalEnv = () => {
    const getStore = localStorage.getItem("LocalEnv");
    return getStore ? JSON.parse(getStore) : {}
}

export const StoreEnv = (envName : keyof LocalEnv, envValue : any) => {
    let LocalEnv : LocalEnv = LoadEnv();
    LocalEnv[envName] = envValue;

    localStorage.setItem("LocalEnv", JSON.stringify(LocalEnv));
}

export const DeleteEnv = (envName : keyof LocalEnv) => {
    let LocalEnv : LocalEnv = LoadEnv();
    delete LocalEnv[envName];

    localStorage.setItem("LocalEnv", JSON.stringify(LocalEnv));
}