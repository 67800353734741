import { ActiveLink } from "raviger";
import { LocalEnv } from "../../types/EnvTypes";
import { DeleteEnv } from "../../utils/LocalEnv";

const sidebarButtons : [boolean, string, string, string][] = [
    [true, "Dashboard", "/dashboard", "gauge"],
    [true, "Media Gallery", "/gallery", "gallery-thumbnails"],
    [true, "Blog", "/blog", "newspaper"],
    [true, "Subscribers", "/subscribers", "envelopes-bulk"],
    [false, "Website", "https://vijaydandiwala.com", "browser"]
]

export default function Sidebar(props : {LocalEnv : LocalEnv}){
    const buttonClass = "p-3 hover:bg-gray-200 block";

    const logoutUser = () => {
        DeleteEnv("auth_token");
        window.location.href = "/login";
    }

    return (
        <div className="fixed inset-y-0 left-0 bg-gray-100 w-[200px] flex flex-col justify-between">
            <div>
            {
                sidebarButtons.map((button, i)=>{
                    const cont = (
                        <>
                            <i className={`far fa-${button[3]} mr-2`}></i>
                            {button[1]}
                        </>
                    );
                    return button[0] ? (
                        <ActiveLink key={i} exactActiveClass="border-l-4 border-l-blue-600" href={button[2]} className={buttonClass}>
                            {cont}
                        </ActiveLink>
                    ) : (
                        <a href={button[2]} key={i} className={buttonClass} target="_blank" rel="noreferrer">
                            {cont}
                        </a>
                    )
                })
            }
            </div>
            <div>
                <button className={buttonClass + " w-full text-left"} onClick={()=>logoutUser()}>
                    <i className={`far fa-arrow-right-from-bracket mr-2`}></i> Logout
                </button>
            </div>
        </div>
    )
}