import { usePath } from "raviger";
import { ReactNode, useEffect } from "react";
import { LocalEnv } from "../types/EnvTypes";
import Sidebar from "./sidebar/Sidebar";

export default function AppContainer(props : {children : ReactNode, localEnv : LocalEnv}){

    const path = usePath();

    useEffect(()=>{
        if(path !== "/login" && !props.localEnv.auth_token){
            window.location.href = "/login";
        }
    }, [path])

    return (
        <>
            <Sidebar LocalEnv={props.localEnv}/>
            <div className="ml-[200px]">
                {props.children}
            </div>
        </>
    )
}