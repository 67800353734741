import { usePath, useRoutes } from "raviger";
import React, { ReactNode } from "react";
import AppContainer from "../components/AppContainer";
import { LocalEnv } from "../types/EnvTypes";
import RouterFallback from "./RouterFallback";

const Dashboard = React.lazy(()=>import("../components/dashboard/Dashboard"));
const Gallery = React.lazy(()=>import("../components/gallery/Gallery"));
const Blog = React.lazy(()=>import("../components/blog/Blog"));
const Login = React.lazy(()=>import("../components/login/Login"));
const Subscribers = React.lazy(()=>import("../components/subscribers/Subscribers"));
const BlogEditor = React.lazy(()=>import("../components/blog/Edit"));
const EditGallery = React.lazy(()=>import("../components/gallery/Edit"));

const fallback = <RouterFallback/>

function LazyLoad(props : {children : ReactNode}){
    return (
        <React.Suspense fallback={fallback}>
            {props.children}
        </React.Suspense>
    )
}

export default function AppRouter(props : {localEnv : LocalEnv}) {

    const path = usePath();

    const dashRoute = (
        <LazyLoad>
            <Dashboard localEnv={props.localEnv}/>
        </LazyLoad>
    );
    const routes = {
        '/' : () => dashRoute,
        '/dashboard' : () => dashRoute,

        '/blog' : () => <LazyLoad>
            <Blog/>
        </LazyLoad>,

        '/gallery' : () => <LazyLoad>
            <Gallery/>
        </LazyLoad>,
        
        "/gallery/:id" : ({id} : {id : string}) => <LazyLoad>
            <EditGallery galleryID={Number(id)}/>
        </LazyLoad>,

        '/subscribers' : () => <LazyLoad>
            <Subscribers/>
        </LazyLoad>,

        '/login' : () => <LazyLoad>
            <Login/>
        </LazyLoad>,
    
        "/blog/:id" : ({id} : {id : string}) => <LazyLoad>
            <BlogEditor bID={id === "new" ? id : Number(id)}/>
        </LazyLoad>,
    }    
    const routeResult = useRoutes(routes);

    return path === "/login" ? routeResult : (<AppContainer localEnv = {props.localEnv}>{routeResult}</AppContainer>);
}