import './App.css';
import AppRouter from './routers/AppRouter';
import { LoadEnv } from './utils/LocalEnv';

function App() {
  const localEnv = LoadEnv();
  return <AppRouter localEnv={localEnv}/>;
}

export default App;
